<template>
  <!-- 作品 -->
  <div class="kj_contact">
    <div
      :class="'current-nav centerText ' + navName"
      v-if="Object.keys(lxData).length != 0"
      :style="{
        background: `url(${lxData.lianxiImg}) no-repeat`
      }"
    ></div>
    <div class="all_no_data" v-else>
      <i class="el-icon-loading"></i>
      <p>数据正在加载</p>
    </div>
    <div class="kj_contact_nav">
      <div class="nav_left centerText">
        <div class="menu_box">
          <h2>{{ lxData.lianxi }}</h2>
          <ul>
            <li
              v-for="(item, idx) in lxData.lianxiList"
              :key="idx"
              :class="[active == idx ? 'cur' : '']"
              @click="active = idx"
            >
              <span>{{ item.key }} {{ item.value }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="nav_right centerText">
        <div class="menu_item" :class="[active == 0 ? 'item_cur' : '']">
          <div class="menu_item_box">
            <i class="el-icon-location-information"></i><span>我们地址</span>
            <div class="address">
              <p>{{ lxwmData.lxwmDz }}</p>
              <p>
                {{ lxwmData.lxwmYw }}
              </p>
            </div>
            <i class="el-icon-phone-outline"></i><span>联系电话</span>
            <div class="phone">
              <p>{{ lxwmData.lxwmDh }}</p>
              <p>{{ lxwmData.lxwmDh2 }}</p>
            </div>
          </div>
        </div>
        <div class="menu_item1" :class="[active == 1 ? 'item_cur' : '']">
          <img :src="lxData.lianxiImg2" alt="" style="width:100%;height:100%">
          <!-- <baidu-map
            class="map"
            :center="centerPoint"
            :zoom="zoom"
            @ready="handler"
            :scroll-wheel-zoom="true"
          >
            <bm-marker
              :position="centerPoint"
              animation="BMAP_ANIMATION_BOUNCE"
              :dragging="true"
              @click="infoWindowOpen"
            >
              <bm-info-window
                style="border: none !important; border-radius: 4px !important;margin-top:10px"
                :show="show"
                @close="infoWindowClose"
                @open="infoWindowOpen"
              >
                {{ mapName }}
              </bm-info-window>
            </bm-marker>
          </baidu-map> -->
        </div>
        <div class="menu_item" :class="[active == 2 ? 'item_cur' : '']">
          <div class="menu_item_box2">
            <h1 class="bottom">{{ jrwmData.jrwm }}</h1>
            <h2 class="bottom">
              请将简历及作品投递至邮箱 <span>{{ jrwmData.jrwmYx }}</span>
            </h2>
            <div v-html="jrwmData.jrwmHtml"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPageMsg } from '@/api/api'
export default {
  components: {},
  data () {
    return {
      navName:'',
      lxData: {},
      lxwmData: {},
      jrwmData: {},
      active: 0,
      address: '',
      centerPoint: {
        lat: 22.574757,
        lng: 113.960667
      },
      mapName: '',
      show: false,
      zoom: 20
    }
  },
  watch: {},
  methods: {
    getData () {
      this.navName = this.changeData() + 'nav'
      let id = sessionStorage.getItem(this.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          this.lxData = res.data.data.lianxi
          this.lxwmData = res.data.data.lxwm
          this.jrwmData = res.data.data.jrwm
          this.mapName = res.data.data.lxwm.lxwmDz
        }
      })
    },
    handler ({ BMap, map }) {
      let that = this
      let data = {}
      const myGeo = new BMap.Geocoder()
      let a = new Promise((resolve, reject) => {
        // 对地址进行地理编码
        myGeo.getPoint(
          that.mapName,
          point => {
            if (point) {
              // 地理编码成功，返回经纬度坐标对象
              resolve(point)
            } else {
              // 地理编码失败
              reject('地理编码失败')
            }
          },
          '全国'
        )
      })
      console.log(a)
      that.centerPoint = {
        lat: 22.574757,
        lng: 113.960667
      }
      var view = map.getViewport(eval())
      //进入显示的百分比
      that.zoom = view.zoom
      //打开地图时的位置
      that.centerPoint = view.center
    },
    infoWindowClose () {
      this.show = false
    },
    infoWindowOpen () {
      this.show = true
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.kj_contact {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 14px;
  .current-nav {
    background-size: 100% 100% !important;
  }
  .no_data {
    height: 400px;
  }
  .kj_contact_nav {
    line-height: 1.3;
    width: 90%;
    height: 500px;
    margin: 50px auto;
    display: flex;
    align-items: center;
    box-shadow: 0 0 10px #ddd;
    .nav_left,
    .nav_right {
      width: 50%;
      text-align: center;
      height: 100%;
      position: relative;
      .menu_box {
        h2 {
          font-size: 36px;
          color: #a7a7a7;
          font-family: 'fm';
          margin-bottom: 25px;
        }
        ul {
          transition: all 0.3s ease;
          li {
            cursor: pointer;
            line-height: 2;
            font-size: 20px;
            transition: color 0.1s ease-in-out;
            color: #a7a7a7;
          }
          li:hover {
            color: #333;
          }
          .cur {
            color: #333;
          }
        }
      }
      .menu_item {
        width: 100%;
        height: 100%;
        color: white;
        background-color: #262626;
        position: static;
        display: none;
        opacity: 0;
        .menu_item_box {
          padding-left: 20%;
          padding-right: 30%;
          box-sizing: border-box;
          text-align: left;
          i {
            font-size: 30px;
          }
          span {
            margin-left: 10px;
          }
          p {
            line-height: 2;
          }
          .address {
            margin: 10px 0;
          }
          .phone {
            margin-top: 10px;
          }
        }
        .menu_item_box2 {
          margin: 0 60px;
          padding: 0 20px;
          max-height: 80%;
          text-align: left;
          .bottom {
            margin-bottom: 20px;
          }
          h1 {
            font-size: 36px;
            color: #acacac;
          }
          h2 {
            color: #999;
            span {
              color: #fff;
            }
          }
        }
      }
      .menu_item1 {
        width: 100%;
        height: 100%;
        display: none;
        opacity: 0;
        .map {
          width: 100%;
          height: 500px;
        }
        #baidumap {
          width: 100%;
          border: 1px solid #ddd;
          height: 100%;
        }
      }
      .item_cur {
        display: flex;
        align-items: center;
        opacity: 1;
      }
    }
  }
}
</style>
